import React from 'react'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import PageFullWidth from 'components/Layout/PageFullWidth'

const Container = styled.div`
    width:100%;
    max-width: 1300px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    flex-direction: column;
`
const NotFound = () => {
  const { t } = useTranslation()

  return (
    <PageFullWidth>
      <CsContainer>
        <ContainerImg/>
      </CsContainer>
    </PageFullWidth>
  )
}

export default NotFound

const ContainerImg = styled(Flex)`
    width:65%;
    height: 595px;
    background-image:url("/images/background_comming_soon.png");
    background-size:contain;
    background-repeat:no-repeat;
    margin-top:0;
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
      height: 400px;
    }
    @media only screen and (max-width: 600px) {
      margin-top:2rem;
      height: 400px;
      width: 100%;
      background-size:contain;
    }
`
export const CsContainer = styled(Container)`
    width: 100%;
    max-width: 1380px;
    height: 100%;
    min-height: 90vh;
    padding-left:0px;
    padding-right:0px;
    padding-top:1.5rem;
    @media screen and (max-width: 768px) and (min-width: 601px) {
        padding-left:0px;
        padding-right:0px;
        margin-bottom:60px;
    }
    @media screen and (max-width: 600px) {
        padding-left:0px;
        padding-right:0px;
    }
`